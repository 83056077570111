<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 sm3 md6 text-left>
        <span class="mainHeader">CUSTOMER SCHEMES LIST</span>
      </v-flex>
      <v-flex xs12 sm3 md2 text-right>
        <v-autocomplete
          background-color="white"
          :items="List1"
          v-model="schemefilter"
          placeholder="Select Scheme"
          item-text="goldSchemeName"
          item-value="_id"
          outlined
          clearable
          hide-details
          class="rounded-xl"
          dense
        ></v-autocomplete>
      </v-flex>
      <v-flex xs12 sm3 md2 text-right pl-1>
        <v-text-field
          background-color="white"
          v-model="keyword"
          color="#b234a9"
          clearable
          hide-details
          placeholder="Search"
          outlined
          class="rounded-xl"
          dense
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-flex>
      <!-------------------------ADD CUSTOMER TO SCHEME----------------------------->
      <v-flex xs12 sm3 md2 class="buttons1" pt-2 pt-sm-0 text-right>
        <v-btn dark color="#3F053C" @click="(addScheme = true)(getCustomer())" class="buttons1">
          <v-icon small>mdi-plus</v-icon> &nbsp; ENLISTING
        </v-btn>
      </v-flex>
       <!-------------------------LIST OF CUSTOMER IN SCHEME----------------------------->
      <v-flex xs12 py-6 text-left>
        <v-card
          class="rounded-lg"
          elevation="0"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4 v-if="schemeList">
            <v-flex xs12 v-if="schemeList.length > 0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left tablefont"><b>S.No.</b></th>
                      <th class="text-left tablefont"><b>Name</b></th>
                      <th class="text-left tablefont"><b>Scheme</b></th>
                      <th class="text-left tablefont"><b>Start Date</b></th>
                      <th class="text-left tablefont"><b>Maturing Date</b></th>
                      <!-- <th class="text-left tablefont"><b>Monthly Deposit(₹)</b></th> -->
                      <th class="text-left tablefont">
                        <b>Yearly Interest(%)</b>
                      </th>
                      <th class="text-left tablefont"><b>Total Amt.(₹)</b></th>
                      <th class="text-left tablefont"><b>Status</b></th>
                      <th class="text-left tablefont"><b>Payment</b></th>
                      <th class="text-left tablefont"><b>Action</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in schemeList" :key="i" class="table">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>
                        <span v-if="value.customerId">{{
                          value.customerId.customerName
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.goldSchemeId">{{
                          value.goldSchemeId.goldSchemeName
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.startDate">{{
                          formatDate(value.startDate)
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.matureDate">{{
                          formatDate(value.matureDate)
                        }}</span>
                        <span v-else>0</span>
                      </td>
                      <td>
                        <span v-if="value.goldSchemeId">{{
                          value.goldSchemeId.yearlyIntrest
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.finalTotalAmount">{{
                          (value.finalTotalAmount).toFixed(2)
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.schemeStatus">{{
                          value.schemeStatus
                        }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <v-layout wrap>
                          <v-flex xs12 class="buttons1">
                            <v-btn 
                              v-if="value.isPaid == true && value.isschemebalanceAdded == true"
                              color="#3F053C"
                              dark
                              @click="$router.push('/GoldSchemePayment?id=' + value._id)
                                  "
                              block
                              small
                              >Remitted</v-btn
                            >
                            <v-btn 
                              v-else-if="value.isPaid == true && value.isschemebalanceAdded == false"
                              color="#3F053C"
                              dark
                              @click="AddToBalance(value._id)"
                              block
                              small
                              >Remit Payment</v-btn
                            >
                            <v-btn
                              v-else
                              color="#3F053C"
                              dark
                              block
                              small
                              @click="$router.push('/GoldSchemePayment?id=' + value._id)
                                  "
                              >Payment</v-btn
                            >
                          </v-flex>
                        </v-layout>
                      </td>
                      <td class="text-center">
                         
                              <v-icon
                            size="18"
                            color="#3F053C"
                            style="cursor: pointer"
                            @click="(deletedialog = true), (curId = value._id)"
                            >mdi-delete</v-icon
                          >
                          
                         
  
                         
                        </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-flex xs12 v-else pa-4 text-center class="tablefont1"> No data found </v-flex>
          </v-layout>
        </v-card>
        <v-layout wrap v-if="schemeList">
          <v-flex xs12 pt-4 v-if="schemeList.length > 0">
            <v-pagination
              small
              color="#3F053C"
              v-model="page"
              :length="Pagelength"
            >
            </v-pagination>
          </v-flex>
        </v-layout>
        <v-dialog width="400px" v-model="deletedialog">
            <v-card width="400px" class="pa-2">
              <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Delete</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="deletedialog = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
              <v-card-text class="pa-4 tablefont"
                >Are you sure you want to delete this customer from this scheme?
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <!-- <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="deletedialog = false"
                  >Cancel</v-btn
                > -->
                <v-btn
                  color="#3F053C"
                  class="button1"
                  dark
                  @click="deleteScheme()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
      <!-------------------------ADD CUSTOMER TO SCHEME DIALOG----------------------------->
        <v-dialog width="800px" v-model="addScheme">
          <v-form v-model="addcat" ref="addcat" @submit.prevent>
            <v-card tile width="1000px" class="px-4 py-4 rounded-lg">
              <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Enlisting</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                    @click="(addScheme = false)(resetForm())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-layout wrap pb-4>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont"> Customer Name </span>
                        </v-flex>
                        <v-flex xs12>
                          <v-autocomplete
                            background-color="white"
                            :items="customerList"
                            v-model="customerdetails"
                            placeholder="Select Customer"
                            item-text="customerName"
                            item-value="_id"
                            outlined
                            hide-details
                            dense
                          ></v-autocomplete>
                          <!-- <v-text-field
                          :rules="[rules.required]"
                          type="text"
                          v-model="customerdetails"
                          outlined
                          dense
                          color="#b234a9"
                          hide-details="auto"
                        ></v-text-field> -->
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Yearly Interest</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            :rules="[rules.required]"
                            type="Number"
                            placeholder="Enter Yearly Interest"
                            v-model="yrlyintrst"
                            outlined
                            dense
                            disabled
                            color="#b234a9"
                            hide-details="auto"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
  <v-layout wrap px-4>
    <v-flex xs12 text-left>
      <span class="tablefont">Start Date</span>
    </v-flex>
    <v-flex xs12>
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            dense
            hide-details="auto"
            append-icon="mdi-calendar-multiselect"
            readonly
            placeholder="Starting Date"
            color="#b234a9"
            v-model="fromDate"
            v-bind="attrs"
            v-on="on"
            @click:clear="fromDate = null"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="fromDate"
          color="#571964"
          @change="menu1 = false"
        ></v-date-picker>
      </v-menu>
    </v-flex>
  </v-layout>
</v-flex>
<v-flex xs12>
  <v-layout wrap px-4>
    <v-flex xs12 text-left>
      <span class="tablefont">Due Date</span>
    </v-flex>
    <v-flex xs12>
      <v-menu
        ref="menu3"
        v-model="menu3"
        :close-on-content-click="false"
        max-width="290"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            dense
            hide-details="auto"
            append-icon="mdi-calendar-multiselect"
            readonly
            placeholder="Due Date"
            color="#b234a9"
            v-model="dueDate"
            v-bind="attrs"
            v-on="on"
            @click:clear="dueDate = null"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dueDate"
          color="#571964"
          :min="computedMinDueDate"
          :max="computedMaxDueDate"
          @change="menu3 = false"
        ></v-date-picker>
      </v-menu>
    </v-flex>
  </v-layout>
</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-layout wrap pb-4>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont"> Scheme Name </span>
                        </v-flex>
                        <v-flex xs12>
                          <v-select
                            color="#b234a9"
                            :items="List1"
                            v-model="goldSchemeName"
                            placeholder="Select Scheme"
                            item-text="goldSchemeName"
                            item-value="_id"
                            @input="selectThis(goldSchemeName)"
                            outlined
                            hide-details
                            dense
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont"> Monthly Deposit </span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            :rules="[rules.required]"
                            type="Number"
                            placeholder="Enter Monthly Deposit"
                            v-model="mnthlydpst"
                            outlined
                            dense
                            disabled
                            color="#b234a9"
                            hide-details="auto"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12>
                      <v-layout wrap px-4>
                        <v-flex xs12 text-left>
                          <span class="tablefont">Maturity Date</span>
                        </v-flex>
                        <v-flex xs12>
                          <v-text-field
                            disabled
                            placeholder="Maturity Date"
                            v-model="secondDate"
                            outlined
                            dense
                            color="#b234a9"
                            hide-details="auto"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6>
                      <v-spacer></v-spacer>
                    </v-flex>
                    <v-flex xs6 pt-7 pr-4>
                      <v-btn
                        type="submit"
                        color="#3F053C"
                        dark
                        block
                        @click="addToScheme()"
                        ><v-icon>mdi-plus</v-icon>Add</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <!-- <v-layout wrap pb-4 justify-end>
                <v-spacer></v-spacer>
                <v-flex xs2 text-right>
                  <v-layout wrap px-4 justify-end fill-height>
                    <v-flex xs12 text-center align-self-end class="buttons1">
                      <v-btn
                        type="submit"
                        color="#3F053C"
                        dark
                        block
                        @click="addToScheme()"
                        ><v-icon>mdi-plus</v-icon>Add</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout> -->
            </v-card>
          </v-form>
        </v-dialog>
         <!-------------------------SCHEME PAYMENT LIST DIALOG----------------------------->
        <!-- <v-dialog width="900px" v-model="makePayment">
          <v-form v-model="addcat2" ref="addcat" @submit.prevent>
            <v-card tile width="1000px" class="px-8 py-4 rounded-lg">
             
              <v-layout wrap>
                <v-flex xs4 text-left v-if="paymentDetails.goldSchemeId">
                  <v-layout wrap >
                    <v-flex xs12 class="tablefont2" text-left pb-1>SCHEME:</v-flex>
                    <v-flex xs12 class="tablefont1" text-left pb-1 v-if="paymentDetails.goldSchemeId.goldSchemeName">{{ paymentDetails.goldSchemeId.goldSchemeName }}</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs4 text-left v-if="paymentDetails.startDate">
                  <v-layout wrap>
                    <v-flex xs12 class="tablefont2" text-left pb-1>Starting Date:</v-flex>
                    <v-flex xs12 class="tablefont1" text-left pb-1>{{  formatDate(paymentDetails.startDate)}}</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs3 text-left v-if="paymentDetails.matureDate">
                  <v-layout wrap>
                    <v-flex xs12 class="tablefont2" text-left pb-1>Maturity Date:</v-flex>
                    <v-flex xs12 class="tablefont1" text-left pb-1>{{  formatDate(paymentDetails.matureDate) }}</v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs1 text-right
                  ><v-icon
                    @click="(makePayment = false)(resetForm2())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
              </v-layout>
              <v-layout wrap>
                    <v-flex xs12 py-2>
                     <v-divider></v-divider>
                    </v-flex>
                    </v-layout>
             
              <v-layout wrap>
                <v-flex xs12 v-if="PaymentList">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left tablefont"><b>No.</b></th>
                          <th class="text-left tablefont"><b>Month</b></th>
                          <th class="text-left tablefont">
                            <b>Montly Deposit(₹)</b>
                          </th>
                          <th class="text-left tablefont">
                            <b>Interest(%)</b>
                          </th>
                          <th class="text-left tablefont">
                            <b>Months Covered</b>
                          </th>
                          <th class="text-left tablefont">
                            <b>Interest Amt.(₹)</b>
                          </th>
                          <th class="text-left tablefont">
                            <b>Amount(₹)</b>
                          </th>
                          <th class="text-left tablefont">
                            <b>Type</b>
                          </th>
                          <th class="text-left tablefont"><b>Action</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(value, i) in PaymentList"
                          :key="i"
                          class="table"
                        >
                          <td>{{ i + 1 }}</td>
                          <td>
                            <span v-if="value.paidMonth">{{
                              value.paidMonth
                            }}</span>
                            <span v-else>-</span>
                          </td>

                          <td>
                            <span v-if="value.monthlyFixedAmount">{{
                              value.monthlyFixedAmount
                            }}</span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="value.monthlyInterestRate">
                              {{ value.monthlyInterestRate }}</span
                            >
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="value.monthsCovered">
                              {{ value.monthsCovered }}</span
                            >
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="value.monthlyInterestAmount">
                              {{ value.monthlyInterestAmount.toFixed(3) }}</span
                            >
                            <span v-else>-</span>
                          </td>
                          <td v-if="value.isPaid == true">
                            <span v-if="value.amountPaid">
                              {{ value.amountPaid}}</span
                            >
                            <span v-else>0</span>
                          </td>
                          <td v-else>
                            <v-text-field
                                :disabled="
                                  value.isPaid == true? true : false
                                "
                                placeholder="0"
                                type="number"
                                color="#b234a9"
                                outlined style="border-color: rgba(192, 0, 250, 0.986);"
                                flat
                                v-model="CashAmt"
                                dense
                                hide-details="auto"
                              ></v-text-field>
                          </td>
                          <td>
                            <v-select
                        :items="paymentTypeList"
                        v-model="paytype"
                        label="Select Item"
                        dense
                        hide-details="auto"
                        outlined
                      ></v-select>
                          </td>
                          <td>
                            <v-layout wrap>
                              <v-flex xs12>
                                <v-btn
                                  v-if="value.isPaid == true"
                                  color="#3F053C"
                                  dark
                                  disabled
                                  block
                                  small
                                >
                                  Completed
                                </v-btn>
                                <v-btn
                                  v-else-if="
                                    isPreviousMonth(
                                      value.paidMonth,
                                      value.paidYear
                                    )
                                  "
                                  color="#3F053C"
                                  dark
                                  block
                                  small
                                  
                                >
                                  Pay
                                </v-btn>
                                <v-btn
                                  v-else
                                  color="#3F053C"
                                  dark
                                  disabled
                                  block
                                  small
                                >
                                  -
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-layout>
            </v-card>
          </v-form>
        </v-dialog> -->
         <!-------------------------------------------------------------------------------->
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      page: 1,
      Pagelength: null,

      rules: {
        required: (value) => !!value || "Required.",
        
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        validateDueDate: (value) => {
        if (value < 1 || value > 31) {
          return 'Enter a valid due date between 1 and 31';
        }
        return true;
      }
      },
      addcat: false,
      addcat2: false,
      keyword: null,
      schemefilter: "",
      schemeList: [],
      List1: [],
      PaymentList: [],
      yrlyintrst: "",
      mnthlydpst: "",
      menu1: false,
      fromDate: "",
      secondDate: "",
      goldSchemeName: "",
      monthlyDeposit: "",
      customerId: "",
      addScheme: false,
      customerList: [],
      customerdetails: "",
      selected: [],
      makePayment: false,
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      getCurrentMonthIndex: new Date().getMonth(),
      payDetails: [],
      //payment
      paymentTypeList: ["Cash", "Cheque", "Card"],
      paytype: null,
      pendingAmount: "",
      paymentDetails:[],
      CashAmt:"",
        curId: null,
        deletedialog: false,
        dueDate:"",
        menu3:false,
    };
  },
  mounted() {
    this.getCustomerSchemeList();
    this.getSchemeList();
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
    minDate() {
      if (this.fromDate) {
        const selectedDate = new Date(this.fromDate);
        selectedDate.setMonth(selectedDate.getMonth() + 11);
        return selectedDate.toISOString().slice(0, 10);
      } else {
        return null;
      }
    },
    computedMinDueDate() {
      if (this.fromDate) {
        const minDueDate = new Date(this.fromDate);
        minDueDate.setDate(1);
        minDueDate.setMonth(minDueDate.getMonth() + 1) // Set day to 1
        minDueDate.setDate(1);
        return minDueDate.toISOString().substr(0, 10);
      }
      return null;
    },
    computedMaxDueDate() {
      if (this.fromDate) {
        const maxDueDate = new Date(this.fromDate);
        maxDueDate.setMonth(maxDueDate.getMonth() + 1); // Set month to the next month
        maxDueDate.setDate(1); // Set day to 1
        const lastDay = new Date(
          maxDueDate.getFullYear(),
          maxDueDate.getMonth() + 1,
          0
        ).getDate();
        maxDueDate.setDate(lastDay); // Set day to the last day of the month
        return maxDueDate.toISOString().substr(0, 10);
      }
      return null;
    },
  
  },
  watch: {
    keyword() {
      this.getCustomerSchemeList();
    },
    page() {
      this.getCustomerSchemeList();
    },
    schemefilter() {
      this.getCustomerSchemeList();
    },
    fromDate(newValue) {
      if (newValue) {
        const selectedDate = new Date(newValue);
        const futureDate = new Date(
          selectedDate.setMonth(selectedDate.getMonth() + 11)
        );
        this.secondDate = futureDate.toISOString().slice(0, 10);
        console.log(this.secondDate, "==this.secondDate");
      } else {
        this.secondDate = null;
      }
    },
  },

  methods: {
   
    resetForm() {
      this.$refs.addcat.reset();
    },
    resetForm2() {
      this.$refs.addcat2.reset();
    },
    isPreviousMonth(paidMonth, paidYear) {
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();

      const currentMonthName = this.monthNames[currentMonth];
      const currentYearString = currentYear.toString();

      const paidMonthIndex = this.monthNames.indexOf(paidMonth);
      const currentMonthIndex = this.monthNames.indexOf(currentMonthName);

      return (
        paidYear < currentYearString ||
        (paidYear === currentYearString && paidMonthIndex <= currentMonthIndex)
      );
    },
    selectThis(id) {
      console.log("id==", id);
      this.selected = this.List1.filter((x) => x._id == id);
      this.yrlyintrst = this.selected[0].yearlyIntrest;
      this.mnthlydpst = this.selected[0].minmonthlydeposit;
      console.log("yrlyintrst==", this.yrlyintrst);
      console.log("mnthlydpst==", this.mnthlydpst);
      console.log("selected==", this.selected);
    },
    getCustomer() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/customer/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.customerList = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.showsnackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getCustomerSchemeList() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/goldscheme/customer/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          scheme: this.schemefilter,
          keyword: this.keyword,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.schemeList = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.showsnackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getSchemeList() {
      // this.appLoading = true;
      axios({
        method: "get",
        url: "/goldscheme/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.List1 = response.data.data;
            // this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.showsnackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
   
    addToScheme() {
      axios({
        url: "/goldscheme/customer/add",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          customerId: this.customerdetails,
          goldSchemeId: this.goldSchemeName,
          matureDate: this.secondDate,
          startDate: this.fromDate,
          dueDate: this.dueDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.addScheme = false;
            this.customerdetails = "";
            this.yrlyintrst = "";
            this.fromDate = "";
            this.goldSchemeName = "";
            this.mnthlydpst = "";
            this.secondDate = "";
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getCustomerSchemeList();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            //  this.getCustomerSchemeList();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    AddToBalance(id) {
      axios({
        url: "/goldscheme/addbalance/customer",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          payId:id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
          
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getCustomerSchemeList();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            //  this.getCustomerSchemeList();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteScheme() {
        axios({
          url: "/goldscheme/customer/delete",
          method: "GET",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            id: this.curId,
          },
        })
          .then((response) => {
            this.deletedialog = false;
            this.appLoading = false;
            if (response.data.status == true) {
              this.msg = response.data.msg;
              this.showsnackbar = true;
              this.getCustomerSchemeList();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
  <style scoped>
.v-text-field input {
  font-family: kumbhBold;
}
</style>
  